@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: 'ToyotaType-Regular', sans-serif;
$boldFontFamily: 'ToyotaType-Semibold', sans-serif;
$boldFontWeight: 400;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #303030;
$linkColor: #1288dc;
$labelColor: #999999;
$formColor: #333333;
$utilColor: #1288dc;
$priceColor: #e1261c;
$lightColor: #999999;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #303030;
$titleSecondaryColor: #ffffff;
$subtitleColor: #333333;
$subtitleSecondaryColor: #ffffff;
$successColor: #303030;
$warningColor: #0071b3;
$errorColor: #ff3526;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #e1261c;
$colorBtnAlpha: #fff;
$bgBtnBeta: #0079c0;
$colorBtnBeta: #fff;
$colorBtnOutlineAlpha: #555555;
$colorBtnOutlineBeta: #0079c0;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #16181e;
$calculatorTabsTextColor: #dedede;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #e3e3e3;
$calculatorMainColor: #ffffff;
$calculatorButton: #e1261c;

$garageBackground: #ffffff;
$garageTextColor: #303030;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #1b1b1b;
$colorTopbarLogin: #c8c9cb;

$progressBarTitleBG: #0079c0;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #0079c0;
$progressBarCloseButtonBG: #0079c0 ;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #171921;
$paymentCalculatorClosedColor: #e8e8e8;
$paymentCalculatorPanelBG: #21232E;
$paymentCalculatorTermWrapperBG: #363a4d;
$paymentCalculatorTermWrapperColor: #aaaaaa;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #252835;
$paymentCalculatorTermColor: #aaaaaa;

// ========
// Styles
// ========
@include breakpoint(mobile){
    .widget-sr{
        &.makes__toyota{
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha;
                .is-price{
                    color: $colorBtnAlpha;
                }
                span{
                    color: $colorBtnAlpha;
                }
            }
        }
    }
}
.widget-sr{
    @include clearfix;
    width: 100%;

    &.makes__toyota{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        h4{
            letter-spacing: 0px;
        }
        .listing-tile .listing-tile-car-save.has-warning-background{
            background-color: $priceColor;
        }
        .TransactionSummaryUnit--single .validFlag:before{
            background-image: -webkit-linear-gradient(304deg,transparent 50%,#4777B7 55%);
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate{
            border-bottom-color: #4777B7;
        }
        .section-checkout-header__progressBar-stage.validated .picto-showroom:first-child{
            color: #4777B7;
        }
        .confirm_tradein--icon,
        .confirm_financing--icon,
        .confirm_deposit--icon,
        .timeSavedBlock{
            border-color: #303030;
        }
        .confirm_tradein--icon icon,
        .confirm_financing--icon icon,
        .confirm_deposit--icon icon,
        .confirm_financing--title,
        .confirm_tradein--title,
        .confirm_deposit--title,
        .Appointment__content .appointment__steps--single .timeSaved .doneLabel icon,
        .Appointment__content .appointment__steps--single .timeSaved .doneLabel,
        .Appointment__content .appointment__steps--single.is-validated .title_wrapper>icon,
        .section-checkout-header__progressBar-stage.validated .picto-showroom:first-child
        {
            color: #303030;
        }
        .section-checkout-header__progressBar-stage.selected .picto-showroom:first-child,
        .section-checkout-header__progressBar-stage.selected .picto-showroom{
            color: #0079c0;
        }
        .TransactionProgressBar--progression{
            background-color: #0079c0;
        }
        .showroom-vdp-used-features-vehicle-info,
        .showroom-vdp-used-features-vehicle-info-title,
        .showroom-vdp-used-features-vehicle-info-value,
        .showroom-vdp-used-features-vehicle-info-fuel-info,
        .showroom-vdp-used-features-vehicle-info-fuel-name,
        .financing-section.tile-payment-info .tile-payment-rate,
        .financing-section.tile-payment-info .tile-frequency,
        .vehicle-overview__vehicle-title .trim,
        .financing-section.tile-payment-info .tile-payment-detail.small,
        .financing-section.tile-payment-info .tile-payment-detail,
        .financing-section.tile-payment-info .tile-payment-rate .frequency-highlight,
        .VehicleDisclaimersContent p,
        .showroom-vdp-used-options .showroom-vdp-used-options-list .showroom-vdp-used-options-item
        {
            color: #333333;
        }

        .delivery-options-information-checkboxLabel:before{
            width: 15px !important;
            height: 15px !important;
            top: 3px !important;
        }
        .delivery-options-information-checkbox{
            .checkBoxInput:checked+label:after,
            .checkBoxInput:not(:checked)+label:after{
                width: 8px !important;
                height: 8px !important;
                left: 8px !important;
                top: 4px !important;
            }
        }
        .message-handler{
            &.warning{
                background-color: #0079c0;
            }
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #0079c0;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#0079c0 55%);
            }
        }
        .TransactionProgressBar--progression{
            background-color: #0079c0;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #0079c0;
            }
        }

        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #0079c0!important;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#0079c0 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #0079c0 !important;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border: 1px solid #0079c0 !important;
            .icon,.icon-Crochet{
                color: #0079c0 !important;
            }
        }
        .confirm_deposit--title,
        .confirm_financing--title{
            color: #0079c0;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #0079c0;
            }
        }
        .Appointment__content .appointment__steps--single.is-validated {
            .icon-calendar{
                color: #0079c0 !important;
            }
        }
        .doneLabel {
            color: #0079c0 !important;
            .icon-Crochet{
                color: #0079c0 !important;
            }
        }

        .message-handler{
            &.warning,
            &.success,{
                background-color: #0079c0 !important;
            }
        }
        .has-success-background{
            background-color: #0079c0 !important;
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__toyota{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}